import {Component, OnInit} from '@angular/core';
import {Environment} from "@angular/cli/lib/config/workspace-schema";
import {environment} from "../../../environments/environment";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'agreement',
    templateUrl: 'agreement.component.html'
})

export class AgreementComponent implements OnInit {
    siteURL = environment.siteURL;
    constructor(public _matDialogRef: MatDialogRef<AgreementComponent>
    ) {
    }

    ngOnInit() {
    }
}
