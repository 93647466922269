import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {CreateProductModule} from "./modules/create-product/create-product.module";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'create-password', loadChildren: () => import('app/modules/auth/create-password/create-password.module').then(m => m.CreatePasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)},
            {path: 'personal-data', loadChildren: () => import('app/modules/auth/supplier-personal-data/supplier-personal-data.module').then(m => m.SupplierPersonalDataModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)}
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'products', loadChildren: () => import('app/modules/products/products.module').then(m => m.ProductsModule)},
            {path: 'warehouses', loadChildren: () => import('app/modules/warehouses/warehouses.module').then(m => m.WarehousesModule)},
            {path: 'price-lists', loadChildren: () => import('app/modules/price-lists/price-lists.module').then(m => m.PriceListsModule)},
            {path: 'create-product', loadChildren: () => import('app/modules/create-product/create-product.module').then(m => CreateProductModule)},
            {path: 'settings', loadChildren: () => import('app/modules/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'orders', loadChildren: () => import('app/modules/orders/orders.module').then(m => m.OrdersModule)},
            {path: 'shops', loadChildren: () => import('app/modules/shops/shops.module').then(m => m.ShopsModule)},
            {path: 'order-settings', loadChildren: () => import('app/modules/order-settings/order-settings.module').then(m => m.OrderSettingsModule)},
            {path: 'payments', loadChildren: () => import('app/modules/payments/payments.module').then(m => m.PaymentsModule)},
            {path: 'partnerships', loadChildren: () => import('app/modules/partnerships/partnerships.module').then(m => m.PartnershipsModule)},
            {path: 'routes', loadChildren: () => import('app/modules/routes/routes.module').then(m => m.RoutesModule)},
            {path: 'account', loadChildren: () => import('app/modules/my-account/my-account.module').then(m => m.MyAccountModule)},
            {path: 'activities', loadChildren: () => import('app/modules/activities/activities.module').then(m => m.ActivitiesModule)},
            {path: 'gallery', loadChildren: () => import('app/modules/gallery/upload-gallery.module').then(m => m.UploadGalleryModule)},
            {path: 'managers', loadChildren: () => import('app/modules/managers/managers.module').then(m => m.ManagersModule)},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
