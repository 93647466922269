import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CreateEditShopReqModel, GetShopResModel, ShopModel} from "../../core/interfaces/shop.interface";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: "root"
})

export class ShopsService {
    dataApi = "4feba07aa3599be028bc7666d81c2c158f767ae1"

    constructor(private http: HttpClient) {
    }

    createShops(data: CreateEditShopReqModel): Observable<ApiResultModel<ShopModel>> {
        return this.http.post<any>(`${appConfig.baseUrl}/shops/create-shops`, data);
    }

    editShop(params: string, data: CreateEditShopReqModel): Observable<ApiResultModel<ShopModel>> {
        return this.http.patch<ApiResultModel<ShopModel>>(`${appConfig.baseUrl}/shops/update-shops/${params}`, data);
    }

    getShops(query): Observable<ApiResultModel<GetShopResModel>> {
        return this.http.get<ApiResultModel<GetShopResModel>>(`${appConfig.baseUrl}/shops/get-shops`, {
            params: query
        });
    }

    getShopById(params: string): Observable<ApiResultModel<ShopModel>> {
        return this.http.get<ApiResultModel<ShopModel>>(`${appConfig.baseUrl}/shops/get-shops/${params}`);
    }

    archiveShop(id: string): Observable<ApiResultModel<any>> {
        return this.http.patch<ApiResultModel<any>>(`${appConfig.baseUrl}/shops/shop-archive/${id}`, {});
    }

    getCompanyData(value: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Token ${this.dataApi}`,
            }).set('No-Token-Needed', 'true')
        };
        const body = Object.assign(
            {},
            {query: value}
        );

        return this.http.post<any>('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', body, httpOptions);
    }
}
