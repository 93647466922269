import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CreateProductComponent} from "./create-product.component";
import {SharedModule} from "../../shared/shared.module";
import {MAT_DATE_FORMATS, MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {createProductsRouting} from "./create-product-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {EditorModule} from "@tinymce/tinymce-angular";
import {MatSelectModule} from "@angular/material/select";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {HttpLoaderFactory} from "../../app.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {TreeModule} from "@circlon/angular-tree-component";
import {MatTreeModule} from "@angular/material/tree";
import {MatIconModule} from "@angular/material/icon";
import {FuseAlertModule} from "../../../@fuse/components/alert";
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
    declarations: [
        CreateProductComponent
    ],
    imports: [
        RouterModule.forChild(createProductsRouting),
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatInputModule,
        EditorModule,
        MatOptionModule,
        TranslateModule,
        TranslocoModule,
        TreeModule,
        MatTreeModule,
        MatIconModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatButtonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FuseAlertModule,
    ],
    exports: [
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D'
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy'
                }
            }
        }
    ]
})
export class CreateProductModule {
}

