import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {ShopsService} from "../shops.service";
import {GetShopResModel, ShopModel} from "../../../core/interfaces/shop.interface";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {ApiResultModel} from "../../../core/interfaces/api-res.interface";
import {PaginationModel} from "../../../core/interfaces/pagination.interface";
import {ConfirmPopupComponent} from "../../../shared/confirm-popup/confirm-popup.component";
import {MatDialog} from "@angular/material/dialog";

export enum ShopStateEnum {
    All = 'all',
    Active = 'active',
    Archived = 'archived'
}

@Component({
    selector: 'shop-list-component',
    templateUrl: 'shop-list.component.html'
})

export class ShopListComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isLoading: boolean;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    pagination: PaginationModel;
    shopStateArr = [
        {value: ShopStateEnum.Active, viewValue: ShopStateEnum.Active},
        {value: ShopStateEnum.Archived, viewValue: ShopStateEnum.Archived},
        {value: ShopStateEnum.All, viewValue: ShopStateEnum.All}
    ];
    query = {
        page: 1,
        limit: 10,
        sort: 's.updatedAt',
        order: 'desc',
        search: '',
        state: ShopStateEnum.Active
    };
    shopList: ShopModel[];
    displayedColumns: string[] = ['shopName', 'address', 'contactPersonPhone', 'contactPersonName', 'action'];

    constructor(private router: Router,
                private shopService: ShopsService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.getShops(this.query);
        this.searchShops();
    }

    private getShops(query): void {
        this.shopService.getShops(query).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res: ApiResultModel<GetShopResModel>) => {
                    this.shopList = res.payload.content;
                    this.pagination = res.payload.pagination;
                }
            })
    }

    private searchShops(): void {
        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(10),
            )
            .subscribe(res => {
                this.query.search = res;
                this.getShops(this.query);
            });
    }

    onChangeState(event: ShopStateEnum): void {
        this.query.state = event;
        this.getShops(this.query);
    }

    createEditShop(): void {
        this.router.navigateByUrl('shops/create-edit');
    }

    onSortChange(event: { active: string, direction: string }): void {

    }

    onPaginateChange(event: any): void {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getShops(this.query);
    }

    archive(id: string): void {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            data: {
                title: 'archive',
                message: 'agreeArchiveShop'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined && result !== null) {
                this.shopService.archiveShop(id).subscribe(res => {
                    this.getShops(this.query);
                });
            }
        });
    }

    editShop(id: string): void {
        this.router.navigate(['shops/create-edit'], {queryParams: {shopId: id}});
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

}
