<ng-container *transloco="let t">
    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
        <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent">
            <mat-drawer-content class="flex flex-col">
                <div
                    class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
                    <div
                        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
                        <div
                            class="absolute inset-x-0 bottom-0"
                            *ngIf="isLoading">
                            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                        </div>
                        <div class="text-4xl font-extrabold tracking-tight">{{t('shops')}}</div>
                        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-rounded min-w-64"
                                [subscriptSizing]="'dynamic'">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input
                                    matInput
                                    [formControl]="searchInputControl"
                                    [autocomplete]="'off'"
                                    placeholder="{{t('searchShops')}}">
                            </mat-form-field>
                            <mat-form-field class="fuse-mat-dense fuse-mat-rounded min-w-44 ml-2">
                                <mat-label>{{t('STATE')}}</mat-label>
                                <mat-select [value]="shopStateArr[0].value" (selectionChange)="onChangeState($event.value)">
                                    <mat-option *ngFor="let shopState of shopStateArr" [value]="shopState.value">
                                        {{t(shopState.viewValue)}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button
                                class="justify-content-end ml-2"
                                mat-flat-button
                                (click)="createEditShop()"
                                [color]="'primary'">
                                {{t('addShop')}}
                            </button>
                        </div>
                    </div>
                    <div class="table-container mat-elevation-z6">
                        <table mat-table [dataSource]="shopList || []" matSort (matSortChange)="onSortChange($event)">
                            <ng-container matColumnDef="shopName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="s.shopName"
                                    > {{t('SHOP_ADDRESS_OR_LEGACY_NAME')}} </th>
                                <td mat-cell *matCellDef="let row"> {{row.shopName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="s.companyName">
                                    {{t('ADDRESS')}} </th>
                                <td mat-cell *matCellDef="let row"> {{row.deliveryAddress.address_1 + ' ' + row.deliveryAddress.city}} </td>
                            </ng-container>

                            <ng-container matColumnDef="contactPersonPhone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="s.contactPersonPhone"
                                    > {{t('TEXT_PHONE_NUMBER')}} </th>
                                <td mat-cell
                                    *matCellDef="let row"> {{row.contactPersonPhone}} </td>
                            </ng-container>

                            <ng-container matColumnDef="contactPersonName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="s.contactPersonName"
                                    > {{t('CONTACT_PERSON_NAME')}} </th>
                                <td mat-cell *matCellDef="let row"> {{row.contactPersonName}}</td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let row">
                                    <div class="d-flex">
                                        <button (click)="editShop(row.id)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button (click)="archive(row.id)">
                                            <mat-icon *ngIf="!row.isArchived">archive</mat-icon>
                                            <mat-icon *ngIf="row.isArchived">unarchive</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator
                            class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                            [ngClass]="{'pointer-events-none': isLoading}"
                            [length]="pagination?.totalRecords"
                            [pageIndex]="pagination?.currentPage - 1"
                            (page)="onPaginateChange($event)"
                            [pageSizeOptions]="[10, 25, 50, 100]"
                            [showFirstLastButtons]="true"></mat-paginator>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</ng-container>
