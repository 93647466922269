import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {CreateProductService} from "./create-product.service";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {GetCategoriesResModel, ProductsContentItemModel, SelectedCategoryModel} from "../products/product.types";
import {GlobalService} from "../../core/services/global.service";
import {CdkDropList} from "@angular/cdk/drag-drop";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {FuseAlertType} from "../../../@fuse/components/alert";
import {TranslocoService} from "@ngneat/transloco";
export interface UnitModel {
    id: number
    operationValue: any;
    operator: string;
    unitCode: string;
    unitName: {
        am: string;
        en: string;
        ru: string;
    }
}
@Component({
    selector: 'create-product',
    templateUrl: 'create-product.component.html'
})

export class CreateProductComponent implements OnInit, OnDestroy {
    @ViewChild(CdkDropList) placeholder: CdkDropList;
    createProductForm: FormGroup;
    showDropDown: boolean;
    value: string = '';
    productId: string;
    uploadedImage: any;
    uploadedGallery: any;
    alert: { type: FuseAlertType; message: string } = {
        type: 'error',
        message: ''
    };
    showAlert: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _transformer = (node: any, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            id: node.id
        };
    };
    treeControl = new FlatTreeControl<any>(
        node => node.level,
        node => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    hasChild = (_: number, node: any) => node.expandable;
    units: UnitModel[] = [];
    imageUrl: any[] = [];
    minDate: Date;
    gallery: any;
    uploadImages: any

    constructor(private createProductService: CreateProductService,
                public translate: TranslateService,
                private globalService: GlobalService,
                public fb: FormBuilder,
                public transLocoService: TranslocoService,
                private router: Router,
                public dialog: MatDialog) {
    }

    ngOnInit() {
        this.initForm();
        this.getProductUnits();
        this.getCategories();
    }

    private initForm(): void {
        this.createProductForm = new FormGroup({
            categories: new FormControl([], Validators.required),
            productName: new FormControl('', Validators.required),
            description: new FormControl(''),
            barcode: new FormControl(''),
            color: new FormControl(''),
            width: new FormControl(''),
            height: new FormControl(''),
            weight: new FormControl(''),
            sku: new FormControl(''),
            gallery: new FormControl(''),
            isVisible: new FormControl(true),
            properties: new FormGroup({
                unit: new FormArray([this.getUnitGroup()])
            }),
            unitId: new FormControl()
        })
    }

    get unitFormArray(): FormArray {
        return (this.createProductForm.get('properties') as FormGroup).get('unit') as FormArray;
    }

    public getUnitGroup(): FormGroup {
        return new FormGroup({
            unitId: new FormControl(''),
            name: new FormControl(''),
            refId: new FormControl(''),
            contents: new FormControl(''),
            unitCode: new FormControl('')
        })
    }

    private getProductUnits(): void {
        this.createProductService.getUnits().subscribe((res: ApiResultModel<UnitModel[]>) => {
            this.units = res.payload;
        })
    }

    private getCategories(): void {
        this.createProductService.getCategories().subscribe((res: ApiResultModel<GetCategoriesResModel>) => {
            this.dataSource.data = res.payload.content.filter(item => item.isActive != false);;
        })
    }

    setCategory(category: SelectedCategoryModel): void {
        const categories = [];
        categories.push(category.id);
        this.value = category.name['ru'];
        this.createProductForm.controls['categories'].patchValue(categories);
        this.showDropDown = false;
    }

    createEditProduct() {
        this.createProductForm.controls['gallery'].setValue(this.gallery)
        if (this.createProductForm.valid) {
            this.createProductService.createProduct(this.createProductForm.getRawValue()).subscribe({
                next: (res: ApiResultModel<ProductsContentItemModel>) => {
                    this.productId = res.payload.id;
                    this.router.navigate(['/gallery'], {queryParams: {productId: res.payload.id}});
                },
                error: (err) => {
                    this.showAlert = true;
                    this.alert.message = this.transLocoService.translate(err.error.message);
                }
            })
        }
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    addUnit(event: MouseEvent): void {
        event.stopPropagation();
        (this.createProductForm.get('properties').get('unit') as FormArray).push(this.getUnitGroup());
    }

    removeUnit(event: MouseEvent, i: number) {
        event.stopPropagation();
        (this.createProductForm.get('properties').get('unit') as FormArray).removeAt(i);
    }
}
