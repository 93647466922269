import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from "rxjs";
import {UserEntity, UserEntriesPageable} from "../my-account/team/user.types";
import {appConfig} from "../../core/config/app.config";
import {HttpClient} from "@angular/common/http";
import {Pagination} from "../../core/interfaces/commmon.interfaces";

@Injectable({
    providedIn: "root"
})
export class ManagersService {
    private _managers: BehaviorSubject<UserEntity[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    getUsers(page: number = 1, size: number = 25, sort: string = 'u.id', order: 'asc' | 'desc' | '' = 'asc', search: string = '',
             userType: string = 'manager'
    ):
        Observable<UserEntriesPageable>
    {
        return this._httpClient.get<UserEntriesPageable>(`${appConfig.baseUrl}/users/get-users`, {
            params: {
                orderBy: sort,
                orderDirection: order,
                page: '' + page,
                limit: '' + size,
                search,
                userType: userType
            }
        }).pipe(
            tap((response) => {
                this._pagination.next(response.payload.pagination)
                this._managers.next(response.payload.content);
            })
        );
    }

    addManager(body): Observable<any> {
        return this._httpClient.post<any>(`${appConfig.baseUrl}/users/add-supplier-manager-user`, body)
    }
}
