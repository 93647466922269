import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CdkDragEnter, CdkDropList} from "@angular/cdk/drag-drop";
import {TranslationKeys} from "../../../@fuse/components/translation-keys";
import {FuseAlertType} from "../../../@fuse/components/alert";
import {AlertTypesEnum} from "../../core/enums/alert-types.enum";
import {ConfirmPopupComponent} from "../confirm-popup/confirm-popup.component";
import {ActivatedRoute} from "@angular/router";
import {ProductsService} from "../../modules/products/products.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslocoService} from "@ngneat/transloco";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-upload-image-popup',
    templateUrl: './upload-image-popup.component.html',
    styleUrls: ['./upload-image-popup.component.scss']
})
export class UploadImagePopupComponent implements OnInit{
    @ViewChild(CdkDropList) placeholder: CdkDropList;
    productId: string;
    gallery = [];
    imagePath = environment.imagePath;
    translationKeys = TranslationKeys;
    imageUrl = [];
    private imagesForUpload: any[] = [];
    private target: CdkDropList = null;
    formData: FormData;
    alert: { type: FuseAlertType; message: string } = {
        type: AlertTypesEnum.Success,
        message: ''
    };
    showAlert: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
                private _productsService: ProductsService,
                private dialog: MatDialog,
                private cd: ChangeDetectorRef,
                public _matDialogRef: MatDialogRef<UploadImagePopupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private transLocoService: TranslocoService) {
    }

    ngOnInit() {
        this.productId = this.data.productId;
        this.getProduct();
    }

    private getProduct(): void {
        this._productsService.getProductById(this.productId).subscribe(res => {
            this.gallery = res.payload.gallery;
        })
    }

    openConfirmDialog(item: any, ind: any) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            data: {
                title: 'delete',
                message: 'agreeDeleteImage'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined && result !== null) {
                this.deleteFromArray(item, ind);
            }
        });
    }

    deleteFromArray(file, index): void {
        this.gallery.splice(index, 1);
        this._productsService.deleteGalleryImage(file.id).subscribe({
            next: () => {
                this.showAlert = true;
                this.alert.message = this.transLocoService.translate(this.translationKeys.ImageDelete);
            },
            error: (err) => {
                this.showAlert = true;
                this.alert.type = AlertTypesEnum.Error;
                this.alert.message = this.transLocoService.translate(err.error.message);
            }
        })
    }

    onDropListEntered({item, container}: CdkDragEnter) {

    }

    onDropListDropped() {


    }

    onFileChange(pFileList: File[] | any) {
        this.showAlert = false;

        Object.keys(pFileList).map(key => {
            let existFile

            if (this.gallery) {
                existFile = this.gallery.find(item => item.originalName === pFileList[key].name);
            } else {
                this.gallery = []
            }

            if (!existFile) {
                this.imagesForUpload.push(pFileList[key])

                this.formData = new FormData();

                this.imagesForUpload.forEach((file, index) => {
                    this.formData.append('files', file);
                    let reader = new FileReader();
                    reader.onload = (e: any) => {
                        this.imageUrl[index] = e.target.result;
                    };
                    reader.readAsDataURL(file);
                })
            }
        })
        this.cd.markForCheck();
    }

    removeImage(url: any, ind: any): void {
        this.imageUrl.splice(ind, 1);
        this.imagesForUpload.splice(ind, 1);
    }

    uploadGallery(): void {
        this._productsService.uploadGallery(this.productId, this.formData).subscribe({
            next: (res: any) => {
                this.gallery.push(res.payload);
                this.imagesForUpload = [];
                this.imageUrl = [];
                this.showAlert = true;
                this.alert.type = AlertTypesEnum.Success;
                this.alert.message = this.transLocoService.translate(TranslationKeys.UploadSuccess);
                this._matDialogRef.close(true);
            },
            error: (err) => {
                this.alert.type = AlertTypesEnum.Error;
                this.alert.message = this.transLocoService.translate(err.error.message);
            }
        })
    }
}
