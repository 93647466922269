import {NgModule} from '@angular/core';
import {CommonModule, JsonPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmPopupComponent} from './confirm-popup/confirm-popup.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MapComponent} from "./map/map.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {AgreementComponent} from "./agreemnet-popup/agreement.component";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {UploadAvatarComponent} from "./upload-avatar-logo/upload-avatar.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {TranslocoModule} from "@ngneat/transloco";
import {OnlyNumberDirective} from "../../@fuse/directives/only-number.directive";
import { UploadImagePopupComponent } from './upload-image-popup/upload-image-popup.component';
import {FuseAlertModule} from "../../@fuse/components/alert";
import {NumberWithSpacesPipe} from "./pipes/number-with-spaces.pipe";
import { PriceListsPopupComponent } from './price-lists-popup/price-lists-popup.component';
import { WarehousesPopupComponent } from './warehouses-popup/warehouses-popup.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatRadioModule} from "@angular/material/radio";
import {TextTruncatePipe} from "../../@fuse/pipes/truncate.pipe";
import {ShopAddressesComponent} from "./shop-addresses-popup/shop-addresses.component";
import {TimePickerComponent} from "./time-picker/time-picker.component";
import {CustomersPopupComponent} from "./customers-popup/customers-popup.component";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatRadioModule,
        MatInputModule,
        MatIconModule,
        TranslocoModule,
        MatTooltipModule,
        MatButtonModule,
        MatSnackBarModule,
        FuseAlertModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MapComponent,
        AgreementComponent,
        OnlyNumberDirective,
        TextTruncatePipe,
        NumberWithSpacesPipe,
        TimePickerComponent,
        CustomersPopupComponent
    ],
    declarations: [
        ConfirmPopupComponent,
        MapComponent,
        AgreementComponent,
        UploadAvatarComponent,
        OnlyNumberDirective,
        UploadImagePopupComponent,
        NumberWithSpacesPipe,
        PriceListsPopupComponent,
        TextTruncatePipe,
        WarehousesPopupComponent,
        ShopAddressesComponent,
        TimePickerComponent,
        CustomersPopupComponent
    ]
})
export class SharedModule {
}
