import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UploadAvatarComponent} from "../../../../shared/upload-avatar-logo/upload-avatar.component";
import {OrderSettingsService} from "../../../../modules/order-settings/order-settings.service";
import {environment} from "../../../../../environments/environment";

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    logo: string;
    imagePath = environment.imagePath;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dialogRef: MatDialogRef<UploadAvatarComponent>;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private dialog: MatDialog,
        private _fuseNavigationService: FuseNavigationService,
        private changeDetection: ChangeDetectorRef,
        private applicationService: OrderSettingsService
    )
    {
    }

    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    ngOnInit(): void
    {
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._userService.uploadedLogo.subscribe(res => {
            if (res) {
                this.getSupplier();
            }
        })
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void
    {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            navigation.toggle();
        }
    }

    changeLogoType(): void {
        this.dialogRef = this.dialog.open(UploadAvatarComponent, {
            data: {
                uploadImage: true
            }
        });

        this.dialogRef.afterClosed().subscribe({
            next: (res) => {
                if (res) {
                    this._userService.uploadLogo(this.user.supplier.id, res).subscribe(res => {
                        this.getSupplier();
                    })
                }
            },
            error: (error) => {
            }
        })
    }

    private getSupplier(): void {
        this.applicationService.getSupplier(this.user.id).subscribe(res => {
            this.logo = res.payload.logoType?.thumbnail?.url;
            this.changeDetection.markForCheck();
        })
    }
}
