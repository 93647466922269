<ng-container *transloco="let t">
    <div
        class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible"
        cdkScrollable>

        <div class="inline-block p-6 sm:p-10 text-left print:p-0">

            <div
                class="w-240 p-16 rounded-2xl shadow bg-card print:w-auto print:rounded-none print:shadow-none print:bg-transparent">
                <div class="text-4xl font-extrabold tracking-tight">{{t('createProduct')}}</div>

                <form [formGroup]="createProductForm" class="flex flex-col mt-8 p-8 pb-4 bg-card overflow-hidden">
                    <div class="flex flex-col gt-x:flex-row mt-5">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('categories')}}</mat-label>
                            <input matInput (focus)="showDropDown = true" [value]=value
                                   readonly required>
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                        <div *ngIf="showDropDown" style="height: 300px; overflow: auto">
                            <mat-tree *ngIf="showDropDown" [dataSource]="dataSource" [treeControl]="treeControl">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="cursor: pointer"
                                               (click)="setCategory(node)">
                                    <button mat-icon-button disabled></button>
                                    {{ node.name[transLocoService.getActiveLang()] }}
                                </mat-tree-node>
                                <mat-tree-node style="cursor: pointer" *matTreeNodeDef="let node;when: hasChild"
                                               matTreeNodePadding
                                               (click)="setCategory(node)">
                                    <button mat-icon-button matTreeNodeToggle
                                            [attr.aria-label]="'Toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                        </mat-icon>
                                    </button>
                                    {{ node.name[transLocoService.getActiveLang()] }}
                                </mat-tree-node>
                            </mat-tree>
                        </div>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('productName')}}</mat-label>
                            <input matInput type="text" formControlName="productName" maxlength="100" required
                                   #productName>
                            <mat-hint align="end">{{productName.value.length}} / 100</mat-hint>
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <label>{{t('productDescription')}}</label>
                        <editor apiKey="1czzjb6nblujmu179wm2747bfyxbaoy2xnxj1kosj6cm14tw"
                                [init]="{plugins: 'link'}"
                                formControlName="description"></editor>
                    </div>

                    <div class="flex flex-col gt-x:flex-row mt-5">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('barcode')}}</mat-label>
                            <input matInput type="text" formControlName="barcode">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div formArrayName="properties">
                        <div class="flex gt-x:flex-row" style="justify-content: space-between"
                             *ngFor="let unit of unitFormArray.controls; let i = index"
                             [formGroup]="unit">
                            <mat-form-field appearance="outline" class="mat-form-field">
                                <mat-label>{{ t('unit') }}</mat-label>
                                <mat-select formControlName="unitId" required>
                                    <mat-option *ngFor="let unit of units" [value]="unit.id">
                                        {{ unit.unitName[transLocoService.getActiveLang()] }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{ t('requiredField') }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="mat-form-field">
                                <mat-label>{{ t('name') }}</mat-label>
                                <input type="text" matInput formControlName="name">
                                <mat-error>{{ t('requiredField') }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="mat-form-field">
                                <mat-label>{{ t('contents') }}</mat-label>
                                <input type="number" min="1" matInput onlyNumber formControlName="contents">
                                <mat-error>{{ t('requiredField') }}</mat-error>
                            </mat-form-field>
                            <button class="mt-2" *ngIf="i < 1" matSuffix mat-icon-button (click)="addUnit($event)">
                                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            </button>
                            <button class="mt-2" *ngIf="i > 0" matSuffix mat-icon-button
                                    (click)="removeUnit($event, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{ t('baseUnit') }}</mat-label>
                            <mat-select formControlName="unitId" required>
                                <mat-option *ngFor="let unit of units" [value]="unit.id">
                                    {{ unit.unitName[transLocoService.getActiveLang()] }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('color')}}</mat-label>
                            <input matInput type="text" formControlName="color">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('width')}}</mat-label>
                            <input matInput type="text" formControlName="width">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('height')}}</mat-label>
                            <input matInput type="text" formControlName="height">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('weight')}}</mat-label>
                            <input matInput type="text" formControlName="weight">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-col gt-x:flex-row">
                        <mat-form-field appearance="outline" class="mat-form-field">
                            <mat-label>{{t('sku')}}</mat-label>
                            <input matInput type="text" formControlName="sku">
                            <mat-error>{{t('requiredField')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="d-flex flex-column m-3">
                        <mat-label>{{t('showProduct')}}</mat-label>
                        <mat-slide-toggle formControlName="isVisible"></mat-slide-toggle>
                    </div>
                    <fuse-alert
                        class="mt-8 -mb-4"
                        *ngIf="showAlert"
                        [appearance]="'outline'"
                        [showIcon]="false"
                        [type]="alert.type">
                        {{alert.message}}
                    </fuse-alert>
                    <div class="flex items-center justify-end mt-5">
                        <button class="fuse-mat-button-large ml-4" mat-flat-button
                                [disabled]="createProductForm.invalid"
                                [color]="'primary'" type="button" (click)="createEditProduct()">
                            {{t('save')}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>
