import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-confirm-popup',
    templateUrl: './confirm-popup.component.html',
    styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit{

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public _matDialogRef: MatDialogRef<ConfirmPopupComponent>,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    closeDialog() {
        this._matDialogRef.close(null);
    }

    confirm() {
        this._matDialogRef.close(true);
    }
}
