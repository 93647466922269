import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AddressModel} from "../../core/interfaces/address.interface";
import {ShopModel} from "../../core/interfaces/shop.interface";
import {PersonModel} from "../../core/interfaces/person.interface";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {appConfig} from "../../core/config/app.config";

export class CustomerEntity {
    id?: string;
    firstName: string;
    lastName: string;
    passport: string;
    emailEmail: boolean;
}

export interface Meta {
    currentPage: number;
    skippedRecords: number
    totalRecords: number;
    totalPages: number;
    hasNext: boolean;
}

export interface CustomerEntriesPageable {
    payload: {
        content: CustomerEntity[];
        totalRecords: number;
        pagination: Pagination;
    }
}

export interface CustomerModel {
    account?: CustomerAccountModel
    createdAt: string;
    customerSettings: CustomerSettingsModel;
    id: string;
    package: any;
    person: PersonModel;
    shops?: ShopModel[];
    user?: {
        email: string;
        id: number;
        mobile: string;
        username: string;
    }
    status?: {
        active: boolean;
        createdAt: string;
        id: number;
        name: string;
        permissions: any[];
        updatedAt: string;
    }
    updatedAt: string;
}

export interface CustomerSettingsModel {
    id: number;
    paymentMethod: string;
    paymentMethodDetails: any;
}

export interface CustomerAccountModel {
    createdAt: string;
    credit: any;
    deposit: number;
    id: number;
    reward: number;
    updatedAt: string;
}

export interface CustomerLoginReqModel {
    login: string;
    password: string;
}

export interface CustomerLoginResModel {
    token: {
        accessToken: string;
        accessTokenExpires: string;
        refreshToken: string;
        tokenType: string;
    };
    user: CustomerUserModel;
}

export interface CustomerUserModel {
    avatar: string;
    customer: {
        id: number,
        person: PersonModel,
        status: any
    };
    email: string;
    id: number;
    isEmailConfirmed: boolean;
    isMobileConfirmed: boolean;
    mobile: string;
    permissions: any;
    roles: CustomerRolesModel;
    status: string;
    username: string;
}

export interface CustomerRolesModel {
    active: boolean;
    id: number;
    name: string;
    permissions: any;
}

export interface VerifyStepsModel {
    verificationCode?: string;
    verificationCodeDuration?: number;
    mobilePhone?: number;
    type?: string;
}

export interface CustomerAuthReqModel {
    mobile?: string | number;
    token?: string | number;
    password?: string;
}

export interface CustomerCreateEditResModel {
    id: string;
    person: PersonModel;
    status: string;
}

export interface CustomerCreateEditReqModel {
    address: AddressModel;
    birthDate: string;
    email: string;
    fatherName: string;
    firstName: string;
    inn: any;
    issueDate: string;
    issuedBy: string;
    lastName: string;
    passport: string;
}

export interface CustomerSettingModel {
    id?: number;
    paymentMethod: string;
    paymentMethodDetails: string;
}

@Injectable()
export class CustomersPopupService {

    constructor(private _httpClient: HttpClient) {
    }

    getCustomers(query):
        Observable<CustomerEntriesPageable>
    {
        return this._httpClient.get<CustomerEntriesPageable>(`${appConfig.baseUrl}/users/get-users`, {
            params: query
        });
    }
}
