<ng-container *transloco="let t">

    <div class="flex justify-between">
        <p class="text-3xl"><b>{{t('selectPriceList')}}</b></p>
        <div>
            <mat-form-field appearance="outline" class="mat-form-field">
                <mat-label class="pointer">{{t('search')}}</mat-label>
                <input matInput [formControl]="letter">
            </mat-form-field>
        </div>
    </div>
    <mat-dialog-content class="mat-typography">
        <mat-radio-group
            aria-labelledby="example-radio-group-label"
            class="flex flex-col">
            <mat-radio-button
                              (change)="onSelection($event.value)"
                              [checked]="list.isChecked"
                              *ngFor="let list of priceList" [value]="list">
                {{list.name}}
            </mat-radio-button>
        </mat-radio-group>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="flex justify-between">
            <div class="mt-3">

            </div>
            <div class="flex">
                <div>
                    <mat-paginator class="hide-paginator-label"
                                   (page)="pageEvent = $event; onPaginateChange($event)"
                                   [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
                        showFirstLastButtons></mat-paginator>
                </div>
                <div class="mt-3">
                    <button
                        class="fuse-mat-button-large"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="selectPrice()"
                    >
                        {{t('choose')}}
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-actions>
</ng-container>
