import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    onHideCategoriesMenu: Subject<boolean> = new Subject();

    constructor(private http: HttpClient) {}

    uploadGallery(params: string, files: FormData): Observable<any> {
        return this.http.post<any>(`api/products/upload-gallery/{id}${params}`, files);
    }

    updateGallery(files: FormData): Observable<any> {
        return this.http.put<any>(`api/products/update-gallery`, files);
    }

    deleteGalleryImage(params: number): Observable<any> {
        return this.http.delete<any>(`$api/products/delete-image/${params}`);
    }
}
