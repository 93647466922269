import {Component, OnInit} from '@angular/core';
import {WarehousesObj, WarehousesTypesResObj} from "../../modules/warehouses/warehouses.types";
import {PageEvent} from "@angular/material/paginator";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {UntypedFormControl} from "@angular/forms";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {WarehousesService} from "../../modules/warehouses/warehouses.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-warehouses-popup',
  templateUrl: './warehouses-popup.component.html',
  styleUrls: ['./warehouses-popup.component.scss']
})
export class WarehousesPopupComponent implements OnInit{
    warehouses: WarehousesTypesResObj[];
    pageEvent: PageEvent;
    pagination: PaginationModel;
    selectedList: any;
    query: any = {
        page: 1,
        limit: 10,
        orderBy: 'w.createdAt',
        orderDirection: 'asc',
        search: ''
    };
    letter: UntypedFormControl = new UntypedFormControl();

    constructor(private warehousesService: WarehousesService,
                public _matDialogRef: MatDialogRef<WarehousesPopupComponent>) {
    }

    ngOnInit() {
        this.getWareHouses();
        this.searchList();
    }

    onSelection(list: any): void {
        this.selectedList = list;
    }

    private getWareHouses(query = this.query): void {
        this.warehousesService.getWarehouses(query).subscribe({
            next:(res: ApiResultModel<WarehousesObj>) => {
                this.warehouses = res.payload.content;
                this.pagination = res.payload.pagination;
            }
        })
    }

    onPaginateChange(event: any): void {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getWareHouses(this.query);
    }

    searchList(): void {
        this.letter.valueChanges.subscribe(res => {
            this.query.search = res;
            this.getWareHouses(this.query);
        })
    }

    selectPrice(): void {
        this._matDialogRef.close(this.selectedList);
    }
}
