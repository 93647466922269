import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {CustomerEntity, CustomerEntriesPageable, CustomersPopupService} from "./customers-popup.service";
import {Subject, takeUntil} from "rxjs";
import {Pagination} from "../../core/interfaces/commmon.interfaces";
import {MatDialogRef} from "@angular/material/dialog";
import {ManagersService} from "../../modules/managers/managers.service";

@Component({
    selector: 'customers-popup-component',
    templateUrl: 'customers-popup.component.html',
    providers: [CustomersPopupService]
})

export class CustomersPopupComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    customerListFormGroup: FormGroup;
    pageEvent: PageEvent;
    pagination: Pagination;
    customers: CustomerEntity[] = [];
    selectedCustomers: CustomerEntity[] = [];

    constructor(public _matDialogRef: MatDialogRef<CustomersPopupComponent>,
                private readonly fb: FormBuilder,
                private readonly managerService: ManagersService,
                private readonly customersPopupService: CustomersPopupService) {
    }

    ngOnInit() {
        this.initForm();
        this.getCustomers();
    }

    private initForm(): void {
        this.customerListFormGroup = this.fb.group({
            page: 1,
            limit: 10,
            search: ''
        })

        this.customerListFormGroup.get('search').valueChanges.subscribe(res => {
            this.getCustomers();
        })
    }

    private getCustomers(): void {
        this.managerService.getUsers(this.customerListFormGroup.getRawValue())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: any) => {
                this.customers = res.payload.content;
                this.pagination = res.payload.pagination;
            })
    }

    onPaginateChange(event: any) {
        this.customerListFormGroup.get('page').setValue(event.pageIndex + 1);
        this.customerListFormGroup.get('limit').setValue(event.pageSize);
        this.getCustomers();
    }

    selectCustomer(): void {
        this._matDialogRef.close(this.selectedCustomers);
    }

    toggleSelection(customer: CustomerEntity): void {
        this.selectedCustomers.push(customer);
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
