import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'time-picker',
    template: `
        <div class="time-picker">
            <label class="time-picker-label">{{ label }}</label>
            <div class="time-picker-inputs">
                <select id="hours" class="hour-select" [(ngModel)]="selectedHour" (change)="emitTimeChange()">
                    <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                </select>
                <select id="minutes" class="minute-select" [(ngModel)]="selectedMinute" (change)="emitTimeChange()">
                    <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                </select>
            </div>
        </div>
    `,
    styles: [`
        .time-picker {
            position: relative;
            padding: 13px;
            justify-content: center;
            display: inline-flex;
            width: 200px;
            height: 55px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        .time-picker-label {
            position: absolute;
            top: -11px;
            left: 11px;
            background-color: white;
            padding: 0 5px;
        }

        .time-picker-inputs {
            display: flex;
            align-items: center;
        }

        .hour-select, .minute-select {
            border: none;
            background-color: transparent;
            outline: none;
            font-size: inherit;
            margin: 0 3px;
        }
    `]
})
export class TimePickerComponent {
    @Input() label: string;
    @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();

    hours: string[] = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    minutes: string[] = Array.from({ length: 4 }, (_, i) => (i * 15).toString().padStart(2, '0'));

    selectedHour: string = this.hours[0];
    selectedMinute: string = this.minutes[0];

    emitTimeChange(): void {
        const time = `${this.selectedHour}:${this.selectedMinute}`;
        this.timeChange.emit(time);
    }
}
