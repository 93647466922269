import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {PriceListsObj, PriceListsResObj} from "../../modules/price-lists/price-lists.types";
import {PriceListsService} from "../../modules/price-lists/price-lists.service";
import {PageEvent} from "@angular/material/paginator";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {FormControl, UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-price-lists-popup',
    templateUrl: './price-lists-popup.component.html',
    styleUrls: ['./price-lists-popup.component.scss']
})
export class PriceListsPopupComponent implements OnInit{
    priceList: PriceListsResObj[];
    pageEvent: PageEvent;
    pagination: PaginationModel;
    selectedList: any;
    query: any = {
        page: 1,
        limit: 10,
        orderBy: 'p.createdAt',
        orderDirection: 'asc',
        search: ''
    };
    letter: UntypedFormControl = new UntypedFormControl();
    constructor(public _matDialogRef: MatDialogRef<PriceListsPopupComponent>,
                private priceListsService: PriceListsService
    ) {
    }

    ngOnInit() {
        this.getPriceLists();
        this.searchList();
    }

    private getPriceLists(query = this.query): void {
        this.priceListsService.getPriceLists(query).subscribe({
            next: (res: ApiResultModel<PriceListsObj>) => {
                this.priceList = res.payload.content;
                this.pagination = res.payload.pagination;
            }
        })
    }

    createPriceList(): void {

    }

    onSelection(list: any): void {
        this.selectedList = list;
    }

    onPaginateChange(event: any): void {
        this.query.page = event.pageIndex + 1;
        this.query.limit = event.pageSize;
        this.getPriceLists(this.query);
    }

    searchList(): void {
        this.letter.valueChanges.subscribe(res => {
            this.query.search = res;
            this.getPriceLists(this.query);
        })
    }

    selectPrice(): void {
        this._matDialogRef.close(this.selectedList);
    }
}
