/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'

    },
    {
        id: 'account',
        title: 'myAccount',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'account',
                title: 'account',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/account/my-account'
            },
            {
                id: 'security',
                title: 'security',
                type: 'basic',
                icon: 'heroicons_outline:lock-closed',
                link: '/account/security'
            },
            {
                id: 'users',
                title: 'users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/account/users'
            },
            {
                id: 'apiKeys',
                title: 'apiKeys',
                type: 'basic',
                icon: 'heroicons_outline:lock-closed',
                link: '/account/api-keys'
            }
        ]
    },
    {
        id: 'products',
        title: 'products',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
            {
                id: 'products',
                title: 'products',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/products'
            }, {
                id: 'createProduct',
                title: 'addProduct',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/create-product'
            },
            {
                id: 'warehouses',
                icon: 'heroicons_outline:lock-closed',
                title: 'warehouses',
                type: 'basic',
                link: '/warehouses'
            },
            {
                id: 'priceLists',
                icon: 'heroicons_outline:lock-closed',
                title: 'priceLists',
                type: 'basic',
                link: '/price-lists'
            }
        ]
    },
    {
        id: 'shops',
        title: 'shops',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/shops'
    },
    {
        id: 'orders',
        title: 'orders',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/orders'
    },
    {
        id: 'payments',
        title: 'payments',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/payments'
    },
    {
        id: 'partnerships',
        title: 'partnerships',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
            {
                id: 'partners',
                title: 'partners',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/partnerships/partners'
            },
            {
                id: 'requests',
                icon: 'heroicons_outline:user-group',
                title: 'requests',
                type: 'basic',
                link: '/partnerships/requests'
            }
        ]
    },
    {
        id: 'managers',
        title: 'managers',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/managers'
    },
    {
        id: 'routes',
        icon: 'heroicons_outline:cog',
        title: 'routes',
        type: 'basic',
        link: '/routes/routes-list'
    },
    {
        id: 'activities',
        title: 'activities',
        type: 'basic',
        icon: 'heroicons_outline:menu-alt-2',
        link: '/activities'
    },
    {
        id: 'application',
        icon: 'heroicons_outline:cog',
        title: 'settings',
        type: 'basic',
        link: 'order-settings'
    },
    {
        id: 'signOut',
        title: 'signOut',
        type: 'basic',
        icon: 'heroicons_outline:logout',
        link: '/sign-out'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];
