<ng-container *transloco="let t">
    <div class="w-full max-w-3xl">
        <form [formGroup]="shopForm">
            <div class="flex justify-between">
                <p class="text-3xl"><b>{{t('shops')}}</b></p>
                <div>
                    <mat-form-field appearance="outline" class="mat-form-field">
                        <mat-label class="pointer">{{t('search')}}</mat-label>
                        <input matInput formControlName="search">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="data.isForRoute">
                <p class="text-3xl"><b>{{t('setTime')}}</b></p>
                <div class="flex justify-start mt-3">
                    <time-picker label="{{t('from')}}" (timeChange)="scheduledStartTimeChange($event)"></time-picker>
                    <time-picker label="{{t('to')}}" class="ml-2"
                                 (timeChange)="scheduledFinishTimeChange($event)"></time-picker>
                </div>
            </div>
            <mat-dialog-content class="mat-typography">
                <mat-radio-group
                    style="max-height: 300px"
                    aria-labelledby="example-radio-group-label"
                    class="flex flex-col">
                    <mat-radio-button
                        (change)="onSelection($event.value)"
                        [checked]="shop.isChecked"
                        *ngFor="let shop of shopList" [value]="shop">
                        {{shop.shopName}}
                    </mat-radio-button>
                </mat-radio-group>
            </mat-dialog-content>
            <mat-dialog-actions>

                <div class="flex justify-between">
                    <div class="mt-3">
                    </div>
                    <div class="flex">
                        <div>
                            <mat-paginator class="hide-paginator-label"
                                           (page)="pageEvent = $event; onPaginateChange($event)"
                                           [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
                                           showFirstLastButtons></mat-paginator>
                        </div>
                        <div class="mt-3">
                            <button
                                class="fuse-mat-button-large"
                                mat-flat-button
                                [color]="'primary'"
                                (click)="selectShop()"
                            >
                                {{t('choose')}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-dialog-actions>
        </form>
    </div>
</ng-container>

