<ng-container *transloco="let t">
    <div style="width: 30vw">
        <div class="flex sm:justify-between">
            <h2 mat-dialog-title>{{t('upload')}}</h2>
            <div class="flex sm:justify-between">
                <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                    <input #fileInput id="fileUpload" type="file" name="file" id="files"
                           (change)="onChange($event)" hidden>
                    <label for="fileUpload">
                        <button class="fuse-mat-button-large" (click)="fileInput.click()"
                                mat-flat-button
                                [color]="'primary'">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            {{t('choseImage')}}
                        </button>
                    </label>
                </div>

                <button
                    mat-icon-button
                    [matTooltip]="'Close'"
                    (click)="dialogRef.close()">
                    <mat-icon
                        class="text-black"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
        </div>
        <mat-dialog-content class="mat-typography"  style="overflow: hidden" class="flex">
            <span *ngIf="file?.name" class="m-2">{{file?.name}}</span>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <!-- Submit button -->
            <div class="flex items-center justify-end">
                <button mat-flat-button [color]="'primary'" (click)="uploadAvatar()">
                    {{t('save')}}
                </button>
            </div>
        </mat-dialog-actions>
    </div>
</ng-container>
