import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiKeysModel} from "./order-settings.component";
import {GetSupplierResModel, SupplierSettingsModel} from "../../core/interfaces/supplier-types";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})
export class OrderSettingsService {
    constructor(
        private http: HttpClient
    ) {}

    updateSettings(data: SupplierSettingsModel): Observable<ApiResultModel<SupplierSettingsModel>> {
        return this.http.patch<ApiResultModel<SupplierSettingsModel>>('api/suppliers/update-supplier-settings', data);
    }

    getSupplier(id: string): Observable<ApiResultModel<any>> {
        return this.http.get<ApiResultModel<GetSupplierResModel>>(`${appConfig.baseUrl}/suppliers/get-supplier/${id}`);
    }

    getLanguages(): Observable<any> {
        return this.http.get<any>(`${appConfig.baseUrl}/settings/get-languages`)
    }

    getCurrencies(): Observable<any> {
        return this.http.get<any>(`${appConfig.baseUrl}/settings/get-currencies`)
    }

    getStatues(): Observable<any> {
        return this.http.get<any>(`${appConfig.baseUrl}/access/statuses/get-statuses`)
    }

    getAccounts(params: any): Observable<any> {
        return this.http.get<any>(`${appConfig.baseUrl}/account/get-accounts` , { params: params })
    }

    getApiKeys(): Observable<ApiResultModel<ApiKeysModel[]>> {
        return this.http.get<ApiResultModel<ApiKeysModel[]>>('api/suppliers/get-api-keys');
    }

    generateApiKey(): Observable<ApiResultModel<{apiKey: string}>> {
        return this.http.post<ApiResultModel<{apiKey: string}>>('api/suppliers/generate-api-key', {});
    }

    deleteApiKey(id: string): Observable<number> {
        return this.http.delete<number>(`api/suppliers/delete-api-key/${id}`)
    }
}
