<form [formGroup]="mapForm">
	<mat-form-field appearance="outline" class="mat-form-field w-full">
		<mat-label>{{addressTitle}}</mat-label>
		<input matInput style="width: 100%"
			   autocorrect="off"
			   autocapitalize="off"
               placeholder=" "
			   spellcheck="off"
			   type="text"
			   formControlName="mapAddress"
			   #search required>
		<mat-error *ngIf="showError">{{addressRequired}}</mat-error>
	</mat-form-field>
</form>

<div *ngIf="showMap" class="map" #map></div>

