import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable, ReplaySubject, Subject, tap} from 'rxjs';
import { User } from 'app/core/user/user.types';
import {appConfig} from "../config/app.config";

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    uploadedLogo: Subject<any> = new Subject<any>();

    constructor(private _httpClient: HttpClient)
    {
    }

    set user(value: any)
    {
        this._user.next(value);
    }

    get user$(): Observable<any>
    {
        return this._user.asObservable();
    }

    get(): Observable<any>
    {
        return this._httpClient.get<any>('api/auth').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    update(user: any): Observable<any>
    {
        return this._httpClient.patch<any>('api/auth', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    uploadLogo(params: string, file: FormData): Observable<any> {
        return this._httpClient.post<any>(`${appConfig.baseUrl}/suppliers/upload-logo/${params}`, file)
            .pipe(tap(() => {
                this.uploadedLogo.next(true)
            }));
    }

    uploadCover(params: string, file: FormData): Observable<any> {
        return this._httpClient.post<any>(`${appConfig.baseUrl}/suppliers/upload-cover/${params}`, file);
    }
}
