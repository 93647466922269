import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CreateWarehouseReqObj, WarehousesObj, WarehousesTypesResObj} from "./warehouses.types";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class WarehousesService {

    constructor(private _httpClient: HttpClient) {
    }

    getWarehouses(query?: any): Observable<ApiResultModel<WarehousesObj>> {
        return this._httpClient.get<ApiResultModel<WarehousesObj>>(`${appConfig.baseUrl}/suppliers/get-warehouse-lists`, { params: query });
    }

    getWareHouseById(id: string): Observable<ApiResultModel<WarehousesTypesResObj>> {
        return this._httpClient.get<ApiResultModel<WarehousesTypesResObj>>(`${appConfig.baseUrl}/suppliers/get-warehouse-list/${id}`);
    }

    editWareHouse(id: string, data: CreateWarehouseReqObj): Observable<ApiResultModel<WarehousesTypesResObj>> {
        return this._httpClient.put<ApiResultModel<WarehousesTypesResObj>>(`api/suppliers/update-warehouse/${id}`, data);
    }

    addWarehouse(data: CreateWarehouseReqObj): Observable<ApiResultModel<WarehousesTypesResObj>> {
        return this._httpClient.post<ApiResultModel<WarehousesTypesResObj>>('api/suppliers/create-warehouse', data);
    }

    deleteWarehouse(id: string): Observable<ApiResultModel<any>> {
        return this._httpClient.delete<any>(`api/suppliers/delete-warehouse/${id}`);
    }

}
