import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CreatePriceListReqObj, PriceListsObj, PriceListsResObj} from "./price-lists.types";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {appConfig} from "../../core/config/app.config";

@Injectable({
    providedIn: 'root'
})

export class PriceListsService {

    constructor(private _httpClient: HttpClient) {
    }

    getPriceLists(query?: any): Observable<ApiResultModel<PriceListsObj>> {
        return this._httpClient.get<ApiResultModel<PriceListsObj>>(`${appConfig.baseUrl}/suppliers/get-price-lists`, {params: query});
    }

    getPriceListById(id: string): Observable<ApiResultModel<PriceListsResObj>> {
        return this._httpClient.get<ApiResultModel<PriceListsResObj>>(`${appConfig.baseUrl}/suppliers/get-price-list/${id}`);
    }

    addPriceList(data: CreatePriceListReqObj): Observable<ApiResultModel<PriceListsResObj>> {
        return this._httpClient.post<ApiResultModel<PriceListsResObj>>('api/suppliers/create-price-list', data);
    }

    deletePriceList(id: string): Observable<ApiResultModel<any>> {
        return this._httpClient.delete<any>(`api/suppliers/delete-price-list/${id}`);
    }

    editPriceList(id: string, data: CreatePriceListReqObj): Observable<ApiResultModel<PriceListsResObj>> {
        return this._httpClient.put<ApiResultModel<PriceListsResObj>>(`api/suppliers/update-price-list/${id}`, data);
    }

}
