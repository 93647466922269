<ng-container *transloco="let t">
    <div style="max-width: 80vw">
        <mat-dialog-header>
            <div class="flex sm:justify-between">
                <h1 mat-dialog-title><b>{{t('gallery')}}</b></h1>
                <button
                    mat-icon-button
                    [matTooltip]="'Close'"
                    (click)="_matDialogRef.close()">
                    <mat-icon
                        class="text-black"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
        </mat-dialog-header>
        <mat-dialog-content class="mat-typography" style="overflow: hidden" class="flex">
            <div *ngIf="gallery?.length" class="flex flex-row flex-wrap mt-10">
                <div
                    cdkDropList
                    (cdkDropListEntered)="onDropListEntered($event)"
                    (cdkDropListDropped)="onDropListDropped()"
                    *ngFor="let item of gallery; let ind = index"
                >
                    <div cdkDrag class="mt-5 m-1">
                        <div class="delete_button" (click)="openConfirmDialog(item, ind);">
                            <mat-icon>delete_forever</mat-icon>
                        </div>
                        <div class="image__body">
                            <img class="image__tag" [src]="(imagePath) + item?.thumbnail?.url" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row flex-wrap mt-10" *ngIf="imageUrl?.length">
                <div
                    cdkDropList
                    (cdkDropListEntered)="onDropListEntered($event)"
                    (cdkDropListDropped)="onDropListDropped()"
                    *ngFor="let url of imageUrl; let ind = index"
                >
                    <div cdkDrag class="mt-5 m-1">
                        <div class="delete_button" (click)="removeImage(url, ind);">
                            <mat-icon>delete_forever</mat-icon>
                        </div>
                        <div class="image__body">
                            <img class="image__tag" [src]="url" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type">
                {{alert.message}}
            </fuse-alert>
        </mat-dialog-content>
        <div class="flex justify-between">
            <div class="mt-6">
                <input #fileInput id="fileUpload" type="file" name="file" id="files"
                       (change)="onFileChange($event.target.files)" multiple hidden>
                <label for="fileUpload">
                    <button class="fuse-mat-button-large" (click)="fileInput.click()"
                            mat-flat-button
                            [color]="'primary'">
                        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                        {{t('choseImage')}}
                    </button>
                </label>
            </div>
            <div>
                <button class="fuse-mat-button-large mt-6 justify-content-end" (click)="uploadGallery()"
                        mat-flat-button
                        [disabled]="!formData || (gallery?.length + imageUrl?.length) >= 10"
                        [color]="'primary'">{{t('upload')}}
                </button>
            </div>
        </div>
    </div>

</ng-container>
