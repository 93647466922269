import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {UnitModel} from "./create-product.component";
import {GetCategoriesResModel, ProductCreateReqModel, ProductsContentItemModel} from "../products/product.types";

@Injectable({
    providedIn: 'root'
})
export class CreateProductService {
    constructor(private _httpClient: HttpClient) {
    }

    getUnits(): Observable<ApiResultModel<UnitModel[]>> {
        return this._httpClient.get<ApiResultModel<UnitModel[]>>(`api/settings/get-units`);
    }

    getCategories(): Observable<ApiResultModel<GetCategoriesResModel>> {
        return this._httpClient.get<ApiResultModel<GetCategoriesResModel>>(`api/products-categories/get-categories`);
    }

    createProduct(data: ProductCreateReqModel): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.post<ApiResultModel<ProductsContentItemModel>>(`api/products/create-products`, data);
    }
}

