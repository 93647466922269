<ng-container *transloco="let t">
    <form [formGroup]="customerListFormGroup">
        <div class="flex justify-between">
            <p class="text-3xl"><b>{{t('managers')}}</b></p>
            <div>
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label class="pointer">{{t('search')}}</mat-label>
                    <input matInput formControlName="search">
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-content class="mat-typography">
            <section>
                <div *ngFor=" let user of customers">
                    <div><mat-checkbox
                        [disabled]="!user?.customer"
                        (change)="toggleSelection(user?.customer)">{{user?.username}}</mat-checkbox></div>
                </div>
            </section>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="flex justify-between">
                <div class="mt-3">

                </div>
                <div class="flex">
                    <div>
                        <mat-paginator class="hide-paginator-label"
                                       (page)="pageEvent = $event; onPaginateChange($event)"
                                       [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalPages"
                                       showFirstLastButtons></mat-paginator>
                    </div>
                    <div class="mt-3">
                        <button
                            class="fuse-mat-button-large"
                            mat-flat-button
                            [color]="'primary'"
                            (click)="selectCustomer()">
                            {{t('choose')}}
                        </button>
                    </div>
                </div>
            </div>
        </mat-dialog-actions>
    </form>
</ng-container>
