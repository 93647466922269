import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {ShopStateEnum} from "../../modules/shops/shop-list/shop-list.component";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {GetShopResModel, ShopModel} from "../../core/interfaces/shop.interface";
import {ShopsService} from "../../modules/shops/shops.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'shop-addresses-component',
    templateUrl: 'shop-addresses.component.html'
})

export class ShopAddressesComponent implements OnInit {
    shopForm: FormGroup;
    pageEvent: PageEvent;
    pagination: PaginationModel;
    scheduledStartDate = '00:00';
    scheduledFinishDate = '00:00';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    shopList: ShopModel[] = [];
    private selectedShop: ShopModel;

    constructor(private readonly fb: FormBuilder,
                private readonly shopService: ShopsService,
                public _matDialogRef: MatDialogRef<ShopAddressesComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,) {
    }

    ngOnInit() {
        this.initShopForm();
        this.getShops();
        this.search();
    }

    private initShopForm(): void {
        this.shopForm = this.fb.group({
            page: 1,
            size: 10,
            sort: 's.updatedAt',
            order: 'desc',
            search: '',
            state: ShopStateEnum.Active
        })
    }

    private getShops(): void {
        this.shopService.getShops(this.shopForm.getRawValue()).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res: ApiResultModel<GetShopResModel>) => {
                    this.shopList = res.payload.content;
                    this.pagination = res.payload.pagination;
                }
            })
    }

    onPaginateChange(event: any) {
        this.shopForm.get('page').setValue(event.pageIndex + 1);
        this.shopForm.get('limit').setValue(event.pageSize);
        this.getShops();
    }

    onSelection(list: any): void {
        this.selectedShop = list;
    }

    private search(): void {
        this.shopForm.get('search').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(10),
            )
            .subscribe(res => {
                this.getShops();
            });
    }

    selectShop(): void {
        const data = {
            time: {
                start: this.scheduledStartDate,
                end: this.scheduledFinishDate
            },
            shop: this.selectedShop
        }
        this._matDialogRef.close(this.data.isForRoute ? data : this.shopForm);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    scheduledStartTimeChange(event: string): void {
        this.scheduledStartDate = event;
    }

    scheduledFinishTimeChange(event: string): void {
        this.scheduledFinishDate = event;
    }
}
