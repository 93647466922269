import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberWithSpaces',
})
export class NumberWithSpacesPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) return value?.toString();

        const formattedValue = value?.toFixed(2); // Round to 2 decimal places
        return formattedValue?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
}
