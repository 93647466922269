import {Injectable} from "@angular/core";
import {BehaviorSubject, map, Observable, switchMap, take, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiResultModel} from "../../core/interfaces/api-res.interface";
import {
    AddPriceReqObj,
    ProductFeatured,
    ProductsContentItemModel,
    ProductsDataModel,
    ProductStatus
} from "./product.types";
import {PaginationModel} from "../../core/interfaces/pagination.interface";
import {appConfig} from "../../core/config/app.config";
import {AbstractControl, ValidationErrors, ɵElement, ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class ProductsService
{
    private _products: BehaviorSubject<ProductsContentItemModel[] | null> = new BehaviorSubject(null)
    private _product: BehaviorSubject<ProductsContentItemModel | null> = new BehaviorSubject(null)
    private _pagination: BehaviorSubject<PaginationModel | null> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient)
    {}

    get products$(): Observable<ProductsContentItemModel[]>
    {
        return this._products.asObservable();
    }

    get product$(): Observable<ProductsContentItemModel>
    {
        return this._product.asObservable();
    }

    get pagination$(): Observable<PaginationModel>
    {
        return this._pagination.asObservable();
    }

    getProducts(params?):
        Observable<ApiResultModel<ProductsDataModel>>
    {
        return this._httpClient.get<ApiResultModel<ProductsDataModel>>(`api/products/get-products`, {
            params
        }).pipe(
            tap((response:ApiResultModel<ProductsDataModel>) => {
                this._pagination.next(response.payload.pagination);
            })
        );
    }

    getProductById(id: string): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.get<ApiResultModel<ProductsContentItemModel>>(`api/products/get-products/${id}`).pipe(
            tap((response: ApiResultModel<ProductsContentItemModel>) => {
                this._product.next(response.payload);
            })
        )
    }

    createProduct(): Observable<ProductsContentItemModel>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.post<ProductsContentItemModel>('api/apps/ecommerce/inventory/product', {}).pipe(
                map((newProduct) => {

                    this._products.next([newProduct, ...products]);

                    return newProduct;
                })
            ))
        );
    }

    updateProduct(id: string, product: ProductsContentItemModel): Observable<ApiResultModel<ProductsContentItemModel>>
    {
        return this._httpClient.patch<ApiResultModel<ProductsContentItemModel>>(`api/products/update-products/${id}`, product).pipe(
            tap((response: ApiResultModel<ProductsContentItemModel>) => {
                this._product.next(response.payload);
            })
        )
    }

    updateProductStatus(id: string, status: ProductStatus): Observable<ProductStatus>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.put<ProductStatus>(`${appConfig.baseUrl}/products/update-product-status/${id}`, {
                status
            }).pipe(
                map((updatedProduct) => {

                    this._products.next(products);

                    return updatedProduct;
                }),
            ))
        );
    }

    deleteProduct(id: string): Observable<boolean>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.delete('api/apps/ecommerce/inventory/product', {params: {id}}).pipe(
                map((isDeleted: boolean) => {

                    const index = products.findIndex(item => item.id === id);

                    products.splice(index, 1);

                    this._products.next(products);

                    return isDeleted;
                })
            ))
        );
    }

    addProductFeatured(product: any, body: ɵTypedOrUntyped<{ [K in keyof { section: (string | ((control: AbstractControl) => (ValidationErrors | null)))[]; expireDate: string[]; startDate: string[] }]: ɵElement<{ section: (string | ((control: AbstractControl) => (ValidationErrors | null)))[]; expireDate: string[]; startDate: string[] }[K], null> }, ɵFormGroupValue<{ [K in keyof { section: (string | ((control: AbstractControl) => (ValidationErrors | null)))[]; expireDate: string[]; startDate: string[] }]: ɵElement<{ section: (string | ((control: AbstractControl) => (ValidationErrors | null)))[]; expireDate: string[]; startDate: string[] }[K], null> }>, any>): Observable<ProductFeatured>
    {
        return this.products$.pipe(
            take(1),
            switchMap(products => this._httpClient.post<ProductFeatured>(`${appConfig.baseUrl}/products/add-featured-product/${product.id}`, body).pipe(
                map((featuredProduct) => {

                    this._products.next(products);

                    return featuredProduct;
                }),
            ))
        );
    }

    addPrice(data: AddPriceReqObj): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.post<ApiResultModel<ProductsContentItemModel>>(`${appConfig.baseUrl}/products/add-product-price`, data);
    }

    addStock(data: {inStock: number}): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.post<ApiResultModel<ProductsContentItemModel>>(`${appConfig.baseUrl}/products/add-product-stock`, data);
    }

    deleteGalleryImage(params: number): Observable<any> {
        return this._httpClient.delete<any>(`${appConfig.baseUrl}/products/delete-image/${params}`);
    }

    uploadGallery(params: string, files: FormData): Observable<any> {
        return this._httpClient.post<any>(`${appConfig.baseUrl}/products/upload-gallery/${params}`, files);
    }

    updateProductVisibility(id: string, data: {isVisible: boolean}): Observable<ApiResultModel<ProductsContentItemModel>> {
        return this._httpClient.put<ApiResultModel<ProductsContentItemModel>>(`${appConfig.baseUrl}/products/update-product-visibility/${id}`, data);
    }

    archiveUnArchiveProduct(id: string): Observable<ApiResultModel<ProductsDataModel>> {
        return this._httpClient.patch<ApiResultModel<ProductsDataModel>>(`${appConfig.baseUrl}/products/product-archive/${id}`, {})
    }

}

